// Placeholder to allow defining custom styles that override everything else.
// (Use `_sass/minima/custom-variables.scss` to override variable defaults)

.profile-picture {
  float: left;
  width: 180px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.profile-picture:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 0, 0, 0) 0%,
    rgba(255, 0, 0, 0.65) 100%
  );
}

.flag-emoji {
  font-size: 120%;
  margin: 0 1px;
}

.medium-img {
  text-align: center;
  img {
    max-height: 400px;
  }
  li {
    text-align: left;
  }
}

div.resume {
  max-width: calc(700px - (30px * 2));
  font-size: 80%;
  padding: 20px 0;

  h2 {
    padding-bottom: 5px;
    border-bottom: 1px solid grey;
  }

  a {
    color: black;
  }

  dt {
    float: left;
    clear: left;
    width: 120px;
    font-weight: bold;
  }
  dd {
    margin-left: 120px;
  }
  dl {
    margin-bottom: 5px;
  }

  p {
    margin-top: 0;
    margin-bottom: 5px;
  }

  /* Blockquotes */
  blockquote {
    text-align: center;
  }

  ul {
    margin-bottom: 0px;
  }

  ul.contact-list {
    list-style-type: none;
    margin: 0;

    li {
      display: inline-block;
      margin-right: 10px;
    }

    margin-bottom: 10px;
  }

  .mn-vert {
    display: inline-block;
    font-weight: normal;
    font-size: 1.25em;
    line-height: 1.25em;
    -webkit-writing-mode: vertical-lr;
    -o-writing-mode: vertical-lr;
    -ms-writing-mode: tb-lr;
    writing-mode: tb-lr;
    writing-mode: vertical-lr;
    vertical-align: text-top;
  }

  .tagline {
    padding: 10px 0;
    border-bottom: 1px solid black;
    border-top: 1px solid black;
  }

  hr {
    margin: 10px 0;
    color: black;
    border-top: 1px solid black;
    border-bottom: none;
    border-right: none;
    border-left: none;
  }

  .resume-right-pic {
    float: right;
    width: 60px;
  }

  .last-updated {
    text-align: center;
    font-size: 0.8em;
  }
}

.licence-box {
  padding: 10px;
  padding-bottom: 5px;
  background-color: #e8e8e8;
}

.site-header {
  border: none;
  margin-top: $spacing-unit;
  font-size: $base-font-size;
  display: flex;
}

.site-title {
  @include relative-font-size(2.2);
  line-height: $base-line-height * $base-font-size * 2.2;
  font-weight: 400;
  margin: 0 auto;
  margin-top: $spacing-unit;
  font-family: 'Helvetica Neue', 'Times New Roman', serif !important;

  @include media-query($on-palm) {
    padding-right: 45px;
  }

  &,
  &:visited {
    color: $brand-color-dark;
  }
}

.svg-icon {
  // width: 20px;
  // height: 20px;
  // display: inline-block;
  // fill: currentColor;
  // padding: 5px 3px 2px 5px;
  // vertical-align: text-bottom;
  transform: scale(1.5);
}